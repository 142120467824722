import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es__header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es__header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es__header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es__header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
    </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/know_the_sun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/know_the_sun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/conozca-el-sol/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/sobre_o_sol/">PT</a>
                      </li>
                      <li>
                        <a href="/it/know_the_sun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/know_the_sun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="kts-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x337px_KnowTheSun.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/know_the_sun_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <div className="kts-page container">
              <div className="kts-page__intro">
                <h1>APRENDA SOBRE O SOL</h1>
                <h2>O nosso guia para aproveitar o sol em segurança</h2>
                <p>
                  Todos os protetores solares de PIZ BUIN<sup>®</sup> contêm os
                  nossos filtros de proteção solar avançada UVA/UVB para
                  assegurar uma proteção eficaz ao sol. No entanto, acreditamos
                  que nunca é demais obter informações sobre o sol e sobre os
                  potenciais efeitos que pode ter sobre nós.
                </p>
                <p>
                  Na informação abaixo destacamos alguns factos importantes
                  sobre o sol para que o possa aproveitar da melhor forma, com
                  responsabilidade.{" "}
                </p>
              </div>
              <section id="content">
                <div className="innerContent">
                  <ul className="ktsBlocks">
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">O SOL</h2>
                        <p className="toggle__sub-title">
                          As radiações UVA e UVB são termos com os quais estamos
                          familiarizados, mas o que significam e como é que nos
                          podem afetar? Descubra aqui..{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul id="container_thesun">
                        <li>
                          <div
                            id="ccm-layout-wrapper-5724"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-4-1"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-1 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-4-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  <h2>Radiação</h2>
                                  <p className="p1">
                                    O sol fornece a terra com energia vital e é
                                    o principal fator na determinação do clima.
                                    O sol não envia apenas radiação visível para
                                    a terra, envia também radiação invisível que
                                    pode ter um impacto negativo na nossa saúde
                                    se for recebida em doses demasiado elevadas.
                                  </p>
                                  <p className="p1">
                                    A radiação solar é composta por uma ampla
                                    variedade de raios. Alguns nem sequer chegam
                                    à terra uma vez que são bloqueados pela
                                    camada de ozono. A radiação que atinge a
                                    Terra consiste nos raios visíveis, raios
                                    infravermelhos (IV) e raios ultravioleta
                                    (UV). A radiação ultravioleta é subdividida
                                    em raios UVA, UVB e UVC, dependendo do seu
                                    comprimento de onda. Os raios UVC são também
                                    bloqueados pela camada de ozono.
                                  </p>
                                  <div
                                    id="blockStyle1682TheSunLayout1Cell162"
                                    className=" ccm-block-styles"
                                  >
                                    <img
                                      border="0"
                                      className="ccm-image-block"
                                      alt
                                      src="/assets/images/Radiation_graph_PT.png"
                                      width="640"
                                      height="380"
                                    />
                                  </div>
                                  <h2>Radiação Visível</h2>
                                  <p>
                                    A radiação visível é composta por todas as
                                    cores que vemos a olho nu.
                                  </p>
                                  <p> </p>
                                  <h2>Raios Infravermelhos (IR)</h2>
                                  <p>
                                    Os raios infravermelhos (IR) transportam
                                    raios de energia térmica, permitindo-nos
                                    sentir o calor.
                                  </p>
                                  <p> </p>
                                  <h2>Raios Ultravioleta (UV)</h2>
                                  <p className="p1">
                                    Os raios ultravioleta (UV) são raios
                                    invisíveis e estão divididos em raios UVA,
                                    UVB e UVC. Os raios UVA podem causar
                                    problemas de pele a longo prazo, incluindo
                                    cancro e envelhecimento prematuro da pele,
                                    enquanto que, os raios UVB são os principais
                                    responsáveis pelas queimaduras solares. Os
                                    raios UVA atingem cerca de 10 vezes mais a
                                    superfície da terra do que os raios UVB. Os
                                    raios UVC são absorvidos pela atmosfera da
                                    terra e não afetam a pele.
                                  </p>
                                  <p className="p1">
                                    A intensidade da radiação solar depende de
                                    vários fatores, incluindo a época do ano,
                                    hora do dia, latitude, altitude, reflexões
                                    no solo e condições climáticas. O sol é mais
                                    brilhante ao meio-dia, por exemplo, e em
                                    altas altitudes, havendo menos radição UV
                                    absorvida, pois a camada da atmosfera é mais
                                    fina.
                                  </p>
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="ccm-layout-wrapper-5725"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-8-3"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-3 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-8-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  &nbsp;
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1060TheSun83"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_01.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">80%</span> dos raios
                                  UV penetram as nuvens.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1061TheSun91"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_02.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A neve limpa reflete todos os raios UV até{" "}
                                  <span className="orange">80%</span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1062TheSun92"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_03.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">60%</span> dos raios
                                  UV ocorrem entre as 10 da manhã e as 2 da
                                  tarde.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1063TheSun93"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_04.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A radiação UV aumenta{" "}
                                  <span className="orange">4%</span> a cada{" "}
                                  <span className="orange">300</span> metros de
                                  altitude.
                                </p>
                                <div> </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1064TheSun94"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_05.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A sombra consegue reduzir a exposição aos
                                  raios UV em{" "}
                                  <span className="orange">50%</span> ou mais.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1860TheSun95"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_06.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A meio metro abaixo da superfície da água, a
                                  radiação UV é ainda{" "}
                                  <span className="orange">40%</span> tão
                                  intensa como na superfície.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1066TheSun96"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_07.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A areia branca reflete até{" "}
                                  <span className="orange">15%</span> dos raios
                                  UV.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1067TheSun97"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_08.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A água reflete cerca de{" "}
                                  <span className="orange">25%</span> da
                                  radiação UV.
                                </p>
                              </div>
                            </div>
                          </div>
                          <h2>Efeitos do Sol</h2>
                          <p>
                            O sol faz com que a vida na terra seja possível, mas
                            também pode ter efeitos nocivos - especialmente sem
                            proteção solar suficiente.
                          </p>
                          <p> </p>
                          <h2>Efeitos imediatos</h2>
                          <p>
                            <strong>Pigmentação Imediata</strong>
                            <br />O primeiro efeito de coloração relacionado com
                            a exposição solar é conhecido como "pigmentação
                            imediata", ou uma coloração que aparece durante a
                            exposição e desaparece dentro de 2 horas. Causada
                            pela radiação UVA e pela radiação visível, a
                            pigmentação imediata não protege contra queimaduras
                            solares.
                          </p>
                          <p>
                            <strong>
                              Escurecimento Persistente do Pigmento (EPP)
                            </strong>
                            <br />O EPP aparece durante a exposição solar e
                            permanece por vários dias. Não tem propriedades
                            protetoras.
                          </p>
                          <p> </p>
                          <h2>Efeitos Retardados</h2>
                          <p>
                            <strong>Queimadura de sol</strong>
                            <br />
                            Se não se proteger da elevada exposição aos raios
                            UV, estes raios começarão a penetrar as camadas da
                            pele. Eventualmente, os vasos sanguíneos abaixo da
                            superfície da pele podem expandir-se e libertar
                            fluidos, causando inflamação e vermelhidão. A
                            queimadura solar aparece normalmente pela primeira
                            vez 2 a 6 horas após o início da exposição, atinge a
                            sua intensidade máxima dentro de 24 a 36 horas e
                            desaparece após 3 a 5 dias.
                          </p>
                          <p>
                            <strong>Bronzeado (pigmentação tardia)</strong>
                            <br />O bronzeado aparece 3 a 5 dias após a
                            exposição ao sol, quando as células específicas da
                            pele, chamadas melanócitos, começam a sintetizar
                            melanina, o pigmento natural da pele responsável
                            pelo bronzeado. A melanina é depois transferida para
                            as células vizinhas, chamadas queratinócitos, que
                            são o tipo de células principal da epiderme ou
                            camada superior da pele. Ao contrário da pigmentação
                            imediata, o bronzeado real ajuda a proteger contra
                            os efeitos nocivos da radiação UV. O bronzeado
                            desaparece progressivamente, mas lembre-se que as
                            pessoas com um bronzeado natural também precisam de
                            aplicar protetor solar, uma vez que o bronzeado não
                            oferece proteção suficiente contra a exposição UV.
                          </p>
                          <p> </p>
                          <h2>Efeitos a longo prazo</h2>
                          <p>
                            <strong>O envelhecimento da pele</strong>
                            <br />A exposição solar tem um papel importante no
                            processo de envelhecimento da pele. Até 90% de todos
                            os sinais visíveis de envelhecimento podem ser
                            atribuídos aos efeitos nocivos da exposição solar.
                          </p>
                          <p className="p1">
                            <strong>O cancro da pele (melanoma)</strong>
                            <br />O cancro da pele, ou melanoma, parece ser
                            causado por danos na estrutura do ADN das células da
                            pele que estão expostas ao sol - apenas 5% de todos
                            os carcinomas ocorrem em áreas da pele que não estão
                            expostas. As lesões destas células da pele conduzem
                            ao crescimento de células degeneradas que, por sua
                            vez resultam em cancro. Na Europa, o cancro da pele
                            é muito mais prevalente no norte, onde a pele das
                            pessoas tende a conter menos pigmentação.
                          </p>
                          <p> </p>
                          <h2>Efeitos acidentais</h2>
                          <p>
                            <strong>Intolerâncias solares</strong>
                            <br />O tipo mais comum de erupção cutânea induzida
                            pelo sol é a erupção benigna da radiação do verão
                            (EBRV). Particularmente comum entre as mulheres
                            jovens, a EBRV manifesta-se através de pequenas
                            pápulas (lesões sólidas) na garganta, peito e parte
                            de trás dos antebraços, que podem causar comichão.
                            Os efeitos aparecem passadas algumas horas após a
                            exposição solar intensa e desaparecem dentro de
                            poucos dias.
                          </p>
                          <div id="HTMLBlock891" className="HTMLBlock">
                            <div
                              style={{
                                clear: "both"
                              }}
                            />
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">
                          ESTEJA SEGURO E PROTEGIDO AO SOL
                        </h2>
                        <p className="toggle__sub-title">
                          Os protetores solares da PIZ BUIN<sup>®</sup>{" "}
                          proporcionam-lhe a proteção solar que precisa, mas e o
                          que mais pode fazer para assegurar a máxima segurança
                          durante a exposição solar?{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>Proteção Solar</h2>
                          <p>
                            <strong>
                              Como é que os filtros solares funcionam?
                            </strong>
                            <br />A principal tecnologia utilizada em qualquer
                            protetor solar é o filtro UV. Os filtros UV podem
                            ser químicos ou físicos.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/f47522e78906ab93bd6746d5c4ffa028_f95.png"
                              alt="kts_img_640_chemical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <p> </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Filtro químico
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Os filtros químicos absorvem a radiação UV e
                            convertem-na em raios de comprimento de onda maior,
                            que são menos prejudiciais para a pele.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/3abb2aaf80e1f6d050098a8ca6618a7a_f96.png"
                              alt="kts_img_640_psysical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Filtro Físico
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Os filtros físicos refletem, transmitem ou absorvem
                            parcialmente a radiação e podem proteger contra os
                            raios UVA e UVB. Os dois filtros físicos mais comuns
                            são o dióxido de titânio e óxido de zinco.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <h2>
                            O que significa Fator de Proteção Solar (FPS)?
                          </h2>
                          <p>
                            O Fator de Proteção Solar (FPS), especificado na sua
                            embalagem de protetor solar, diz-lhe qual a
                            quantidade de proteção solar que o seu protetor
                            oferece contra os raios UVB.
                          </p>
                          <p>
                            Em geral, quanto maior o número do FPS do seu
                            protetor, maior a proteção, assim um FPS 6 ou 10
                            oferece baixa proteção, FPS 15, 20 ou 25 oferece
                            proteção média, FPS 30 ou 50 oferece proteção
                            elevada, e SPF 50 + oferece proteção muito elevada.
                            O FPS é medido através de um ensaio padronizado.
                          </p>
                          <p>
                            O número do FPS indica o período de tempo que pode
                            ficar exposto ao sol antes deste começar a queimar.
                            Como funciona: tenha em consideração o intervalo de
                            tempo (em minutos) que normalmente demora para que a
                            sua pele comece a ficar queimada pelo sol sem
                            proteção solar (digamos 15 minutos). De seguida,
                            multiplique o número de minutos pelo número do FPS
                            (neste caso, o FPS 6). 15 x 6 = 90, tem cerca de 90
                            minutos antes de começar a ter uma queimadura solar.
                            Por outras palavras, quanto maior o FPS maior a
                            proteção do seu protetor solar. Mas lembre-se que
                            estes são valores teóricos. Na realidade, o tempo
                            depende, entre outros fatores, do seu fototipo de
                            pele e da intensidade da radiação UV local.
                          </p>
                          <p>
                            Para escolher o FPS certo, precisa de considerar
                            vários fatores. O mais importante é o fototipo da
                            sua pele, que inclui a cor da pele antes da
                            exposição ao sol, a cor do cabelo, a cor dos olhos e
                            outros fatores. Também precisa de ter em
                            consideração o local para onde vai de férias e a
                            época do ano em que vai.
                          </p>
                          <h2> </h2>
                          <h2>O que significa "proteção fotoestável"?</h2>
                          <p>
                            Para proteger a pele, os protetores solares usam
                            filtros UV que absorvem a radiação do sol. Quando um
                            protetor solar é exposto a esta radiação, pode
                            sofrer alterações na estrutura química dos filtros
                            UV, degradando assim a sua capacidade de proteção.
                          </p>
                          <p>
                            Um protetor solar "fotoestável" tem melhor capidade
                            de suportar os efeitos da exposição aos raios UV,
                            mantendo por isso o seu nível de proteção.
                          </p>
                          <p>
                            Todos os protetores solares
                            <span
                              style={{
                                "font-size": "13.333333969116211px"
                              }}
                            >
                              {" "}
                              PIZ BUIN<sup>®</sup>{" "}
                            </span>
                            proporcionam uma proteção fotoestável.
                          </p>
                          <h2> </h2>
                          <h2>O que significa "proteção de amplo espetro"?</h2>
                          <p>
                            Um protetor solar com proteção de amplo espetro
                            significa que tem a capacidade de proteger contra os
                            efeitos nocivos dos raios UVA (raios responsáveis
                            pelo envelhecimento) e UVB (raios responsáveis pelas
                            queimaduras). Para ser classificado como um protetor
                            que proporciona proteção de amplo espetro, um
                            protetor solar tem de absorver ou refletir pelo
                            menos 90% dos raios UV de 290-400 nanómetros (nm) de
                            comprimento de onda.
                          </p>
                          <p>
                            Todos os protetores solares PIZ BUIN<sup>®</sup>{" "}
                            oferecem proteção de amplo espetro.
                          </p>
                          <h2> </h2>
                          <h2>Resistência à água</h2>
                          <p>
                            É importante que o protetor solar seja resistente à
                            água quando vai nadar porque a radiação UV a meio
                            metro abaixo da superfície da água ainda é 40% tão
                            intensa como é na superfície. Também precisa
                            proteger a parte do corpo que está acima da
                            superfície, uma vez que a água reflete cerca de 25%
                            da radiação UV e pode intensificar os efeitos
                            nocivos.
                          </p>
                          <p>
                            Quase todos* os protetores solares PIZ BUIN
                            <sup>®</sup> são resistentes a extra resistentes à
                            água, como o nosso PIZ BUIN WET SKIN<sup>®</sup>{" "}
                            Spray Solar Transparente.
                          </p>
                          <p>
                            *O seguinte produto PIZ BUIN<sup>®</sup> não é
                            resistente à água: PIZ BUIN<sup>®</sup> Allergy
                            Spray FPS30.
                          </p>
                          <div> </div>
                          <h2> </h2>
                          <h2>Dicas de Segurança ao Sol</h2>
                          <p>
                            Ir de férias, muitas vezes significa expor a pele ao
                            sol pela primeira vez depois de um longo inverno de
                            frio e roupas quentes. É importante dedicar uma
                            atenção extra à sua pele antes de sair para se
                            certificar de que está pronto para a mudança
                            repentina.
                          </p>
                          <h3> </h3>
                          <p>
                            <strong>
                              Dicas para uma aplicação segura e eficaz do
                              protetor solar e comportamento ao sol:
                            </strong>
                          </p>
                          <ol>
                            <li>
                              <span>
                                Aplique o protetor solar pelo menos 20 minutos
                                antes da exposição solar para permitir a máxima
                                absorção e proteção.
                              </span>
                            </li>
                            <li>
                              <span>
                                Certifique-se de que está a aplicar uma
                                quantidade de protetor solar suficiente. A
                                aplicação de uma dose reduzida reduz
                                significativamente o nível de proteção. Preste
                                especial atenção às áreas mais sensíveis, como
                                as orelhas, ombros, decote e pescoço.
                              </span>
                            </li>
                            <li>
                              <span>
                                Não se esqueça das áreas de difícil acesso que
                                são facilmente esquecidas, como as orelhas, pés
                                e parte superior das costas.
                              </span>
                            </li>
                            <li>
                              <span>
                                Reaplique o protetor solar a cada 2 horas.
                                Aplique mais frequentemente depois de nadar,
                                transpirar ou secar-se com uma toalha.
                              </span>
                            </li>
                            <li>
                              <span>
                                Evite a exposição solar do meio-dia, entre as
                                11:00 e as 15:00, durante os meses de verão ou
                                quando em férias em países tropicais.
                              </span>
                            </li>
                            <li>
                              <span>
                                Se estiver a tomar alguma medicação, consulte o
                                seu médico ou farmacêutico para se certificar de
                                que pode passar tempo ao sol. Alguns
                                medicamentos podem tornar a pele mais sensível
                                aos raios solares.
                              </span>
                            </li>
                            <li>
                              <span>
                                A exposição excessiva ao sol pode ter efeitos
                                nocivos na sua saúde, por isso evite a exposição
                                solar ao longo de muitas horas, mesmo quando usa
                                proteção solar.
                              </span>
                            </li>
                            <li>
                              <span>
                                Proteja as crianças. As crianças são
                                particularmente vulneráveis aos efeitos da
                                radiação UV e muitas vezes passam mais tempo ao
                                ar livre do que os adultos. Os pais devem ter
                                cuidados especiais para protegê-los do sol
                                usando roupas mais adequadas, chapéus, óculos de
                                sol e protetor solar. Mantenha os bebés e as
                                crianças pequenas afastados da exposição direta
                                ao sol.
                              </span>
                            </li>
                          </ol>
                          <h2> </h2>
                          <h2>
                            <a name="skintypes" />
                            Tipos de pele
                          </h2>
                          <p>
                            Diferentes tipos de pele reagem de formas diferentes
                            ao sol, por isso o tipo de pele que tem influência o
                            intervalo de tempo que pode ficar ao sol e o tipo de
                            protetor solar que precisa.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">FAQ</h2>
                        <p className="toggle__sub-title">
                          O que mais preciso de saber sobre a utilização dos
                          protetores solares de PIZ BUIN<sup>®</sup>{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>
                            Será que usar protetor solar me vai impedir de ficar
                            bronzeado?
                          </h2>
                          <p>
                            Muito pelo contrário. Apesar de a pele mudar de cor
                            mais lentamente quando usa protetor solar, o
                            bronzeado resultante será melhor e mais duradouro
                            uma vez que o protetor solar irá impedir que a pele
                            queime e descame. Alguns dos produtos PIZ BUIN
                            <sup>®</sup> contêm ainda ingredientes especiais que
                            estimulam a produção natural e a distribuição
                            uniforme dos pigmentos naturais da pele responsáveis
                            pelo bronzeado.
                          </p>
                          <p> </p>
                          <h2>
                            Quanto tempo dura o protetor solar após passar do
                            prazo de validade?
                          </h2>
                          <p>
                            Depende se a embalagem foi aberta. Se não tiver sido
                            aberta, poderá usar o produto sem problema. Todos os
                            protetores solares PIZ BUIN<sup>®</sup> são testados
                            de acordo com a legislação da UE e têm uma vida útil
                            de mais de 30 meses. Se a sua embalagem tiver sido
                            aberta há mais de 12 meses, então terá de comprar
                            uma embalagem nova.
                          </p>
                          <p> </p>
                          <h2>
                            POSSO DIMINUIR O NÍVEL DE FPS SE PASSAR MAIS TEMPO
                            AO SOL? COM QUE RAPIDEZ?
                          </h2>
                          <p>
                            Pode diminuir o nível de proteção passo a passo
                            durante as suas férias assim que a sua pele tiver
                            produzido o seu próprio sistema de defesa: o
                            bronzeado. Depois de alguns dias de proteção
                            efetiva, pode experimentar mudar de um protetor de
                            proteção elevada para um de proteção média. Mas
                            lembre-se que as pessoas com um bronzeado natural
                            também precisam de aplicar protetor solar, uma vez
                            que o bronzeado não oferece proteção suficiente
                            contra a exposição UV. Tenha também em consideração
                            que o nível de FPS que precisa depende muito do seu
                            fototipo de pele e da intensidade UV local.
                          </p>
                          <p>
                            Se não tiver certeza sobre o nível de FPS certo para
                            o seu protetor solar, escolha sempre o mais elevado.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\nif(document.location.hash != ''){\nvar anchor = document.location.hash;\n$(\"#container_thesun\").show();\nt = $(anchor);\n$('html,body').animate({scrollTop: t.offset().top},'slow');\n}\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
